import { ComponentType } from 'react'

export const getIcon = (iconName: string, brand = 'telus'): ComponentType => {
  if (!iconName) return

  try {
    const Icon =
      brand === `koodo`
        ? require(`@telus-uds/palette-koodo/build/web/icons/${iconName}.js`)
        : require(`@telus-uds/palette-allium/build/web/icons/${iconName}.js`)
    return Icon
  } catch (e) {
    return undefined
  }
}
